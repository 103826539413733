* {
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
  --primary-color: #098bcc !important;
  --secondary-color: #076a91 !important;
}

th > div > span {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .background-signin {
    background-image: url("images/background-signin.jpg");
    background-size: cover;
    background-position: 50% 75%;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}

.background-signin-v2 {
  background-image: url("images/background-signin.jpg");
  background-size: cover;
  background-position: 50% 75%;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.background-test {
  background-image: url("images/test.png");
  background-size:100% 100%;
  padding-left: 140px;
  padding-top: 230px;
  padding-bottom: 120px;
  padding-right: 140px;
}