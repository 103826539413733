.map-info-wrapper {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    color: var(--black);
    border: solid 2px var(--black);
    border-radius: 6px;
    width: 100%;
    margin-top: 40px;
}

.map-info-wrapper > p {
    font-size: 14px;
    margin: 0;
}

.map-info-wrapper > h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 7px;
}

.map-info-wrapper > p > span {
    font-weight: bold;
}

.map-info-wrapper > hr {
    margin-bottom: 10px;
    margin-top: 10px;
}

.map-legend-item {
    margin-bottom: 7px;
    cursor: pointer;
}

.map-legend-item.active {
    font-weight: bold;
}

.map-legend-item .map-legend-color {
    width: 25px;
    height: 100%;
    margin-right: 10px;
    border: 1px black solid;
}

.map-legend-item.active .map-legend-color {
    border-width: 2px;
}

.map-legend-item .map-legend-text {
    margin: 0;
    font-family: inherit;
    font-size: 15px;
}