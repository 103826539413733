.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%
}

.button-color {
    display: flex;
    width: 100%;
    min-height: 50px;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.4s;
    border-width: 2px;
    border-style: solid;
    padding: 0;
}

.button-color:hover {
  opacity: 0.7;
}

.button_color:active {
  opacity: 0.5;
}
.button-color:focus {
  outline: none;
}

.button-color > span {
    white-space: nowrap;
    margin: auto 20px;
    color: var(--white);
    font-size: 12px;
    font-weight: bold;
}

.button-color > div {
    display: flex;
    width: 40px;
    min-height: 40px;
    height: calc(100% - 6px);
    color: var(--white);
    font-size: 18px;
    cursor: pointer;
    transition: 0.4s;
    margin: 3px;
    border-radius: 10px;
    margin-left: auto;
}

.button-color > div > * {
    margin: auto;
}

.reset-colors {
  font-size: 12px;
  padding-top: 10px;
  padding-right: 10px;
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
  font-weight: bold;
  transition: opacity ease-in 0.2s;
}

.reset-colors:hover,
.reset-colors:active,
.reset-colors:focus {
  opacity: 0.7;
}