.dep {
    stroke-linecap: round;
    stroke-linejoin: round;
}

.dep {
    fill: #fdfdfd;
    stroke: #6b6b6b;
    cursor: pointer;
}

.dep.disabled {
    cursor: not-allowed
}

.group {
    stroke-width: 0;
    transition: all ease 0.4s;
    cursor: pointer;
}

.group.faded {
    opacity: 0.3;
}

.group:hover,
.group:active {
    stroke-width: 5;
}