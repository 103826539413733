.table_title{
  display: flex;
  margin-bottom: 10px;
}

.table_title > span {
  margin-top: 10px;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  margin: auto;
}