.toggled-card-title {
  width: 100%;
  min-height: 50px;
  background-color: var(--secondary-color);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: var(--white);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.toggled-card-title > *:nth-child(1) {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.toggled-card-title > *:nth-child(2) {
  font-size: 1.4rem;
  margin-left: auto;
  margin-right: 15px;
}

.toggled-card-content {
  display: block;
  margin-bottom: 20px;
}

.toggled-card-content.hidden {
  display: none;
}

.FontSize-10 :nth-last-child(1) {
  font-size: 10px;
}