.data-table {
    margin-bottom: 20px;
}

.data-table-empty {
    color: gray;
    font-size: 14px;
    border: 1px solid gray;
    border-radius: 5px;
    text-align: center;
    line-height: 50px;
    height: 50px;
    width: 100%;
}